import { useState, useEffect } from 'react';

import { useOktaAuth } from '@okta/okta-react';


const HOST = process.env.REACT_APP_API_HOST;
const initialFormState = { ID: '', TestKitState: '' };

const useCrud = () => {
  // TODO reuse token instead of fetching it all the time
  // TODO set default headers
  const { authState, oktaAuth } = useOktaAuth();

  const [testKits, setTestKits] = useState<any[]>([]);
  const [currentTestKit, setCurrentTestKit] = useState(initialFormState);
  const [editing, setEditing] = useState(false);
  const [isFetching, setFetching] = useState(false);

  const getAccessToken = async () => {
    return oktaAuth.getAccessToken() || '';
  }

  useEffect(() => {
    async function fetchData() {
      try {
        setFetching(true);
        const token = await getAccessToken() || '';
        const headers: HeadersInit = { token };
        const resp = await fetch(`${HOST}/testkits`, { headers });
        const data: any = await resp.json();
        data?.Items && setTestKits(data.Items);
      } catch (error) {
        console.log('listTestKits', { error });
      } finally {
        setFetching(false);
      }
    }

    if (!!authState && authState.isAuthenticated)
      fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  // CRUD operations
  const addTestKit = async (Count: number) => {
    try {
      setFetching(true);
      const token = await getAccessToken() || '';
      const headers: HeadersInit = { token };
      await fetch(`${HOST}/testkit`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ Count }),
      });
      const resp = await fetch(`${HOST}/testkits`, { headers });
      const data: any = await resp.json();
      setTestKits(data.Items);
    } catch (error) {
      console.log('addTestKit', { error });
    } finally {
      setFetching(false);
    }
  };

  const bulkUploadTestKits = (selectedFiles: any) => {
    try {
      setFetching(true);
      const files: any = [];
      for (const selectedFile of selectedFiles) {
        const fr = new FileReader();
        fr.readAsText(selectedFile);
        // eslint-disable-next-line no-loop-func
        fr.onload = async function () {
          const file = Buffer.from(this.result?.toString() || '');
          files.push({ data: file, name: selectedFile.name });
          if (files.length === selectedFiles.length) {
            console.log({ files });
            const token = await getAccessToken() || '';
            const headers: HeadersInit = { token };
            await fetch(`${HOST}/import`, {
              method: 'POST',
              headers,
              body: JSON.stringify({ files }),
            });
            const resp = await fetch(`${HOST}/testkits`, {
              headers,
            });
            const data: any = await resp.json();
            setTestKits(data.Items);
          }
        };
        fr.onerror = function (ev) {
          console.log('bulkUploadTestKits', { error: 'Read file error' });
          return;
        };
      }
    } catch (error) {
      console.log('bulkUploadTestKits', { error });
    } finally {
      setFetching(false);
    }
  };

  const deleteTestKit = async (ID: string) => {
    try {
      setEditing(false);
      setFetching(true);
      const token = await getAccessToken() || '';
      const headers: HeadersInit = { token };
      await fetch(`${HOST}/testkit/${ID}`, {
        method: 'DELETE',
        headers,
      });
      setTestKits(testKits.filter((testKit) => testKit.ID !== ID));
    } catch (error) {
      console.log('deleteTestKit', { error });
    } finally {
      setFetching(false);
    }
  };

  const updateTestKit = async (ID: string, updatedTestKit: any) => {
    try {
      setEditing(false);
      setFetching(true);
      const token = await getAccessToken() || '';
      const headers: HeadersInit = { token };
      await fetch(`${HOST}/testkit/${ID}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({ ...updatedTestKit }),
      });
      setTestKits(
        testKits.map((testKit) =>
          testKit.ID === ID ? updatedTestKit : testKit
        )
      );
    } catch (error) {
      console.log('updateTestKit', { error });
    } finally {
      setFetching(false);
    }
  };

  const editRow = async (ID: string) => {
    try {
      setEditing(true);
      setFetching(true);
      const token = await getAccessToken() || '';
      const headers: HeadersInit = { token };
      const resp = await fetch(`${HOST}/testkit/${ID}`, { headers });
      const data: any = await resp.json();
      setCurrentTestKit({
        ID: data.Item.ID,
        TestKitState: data.Item.TestKitState,
      });
    } catch (error) {
      console.log('getTestKit', { error });
    } finally {
      setFetching(false);
    }
  };

  const exportTestKits = async () => {
    try {
      setFetching(true);
      const token = await getAccessToken() || '';
      const headers: HeadersInit = { token };
      const resp = await fetch(`${HOST}/export`, { headers });
      const blob = await resp.blob();
      const filename = resp?.headers?.get('Content-Disposition')?.split('=')[1] || '';
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.setAttribute('download', filename);
      a.click();
      setTestKits(
        testKits.map((testKit) =>
          testKit.TestKitState === 'Created - Not Shared'
            ? { ...testKit, TestKitState: 'Created - Shared' }
            : testKit
        )
      );
    } catch (error) {
      console.log('exportTestKits', { error });
    } finally {
      setFetching(false);
    }
  };

  return {
    //Properties
    testKits,
    currentTestKit,
    editing,
    isFetching,
    //Functions
    setEditing,
    //CRUD functions
    addTestKit,
    bulkUploadTestKits,
    deleteTestKit,
    updateTestKit,
    editRow,
    exportTestKits
  };
};

export default useCrud;