import React, { useEffect } from 'react';

import { Autocomplete, Box, TextField } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';


import {
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColumns,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRenderCellParams,
    GridColDef,
    useGridApiContext,
} from '@mui/x-data-grid';
import mirSentinelTheme from '../../mir-styles/src/styles/general';


const validStates = [
    "Created - Not Shared", // has been created by miR. Has not been made available to 3PL
    "Created - Shared", // has been created by miR. Has been made available to 3PL
    "Received", // has been received by 3PL
    "Assigned", // has been assigned to a Collection Kit by 3PL.
    "Discarded", // has been discarded by miR
];

function ComboEditInputCell(props: GridRenderCellParams<string>) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (event: React.SyntheticEvent, newValue: string | null) => {
        const isValid = await apiRef.current.setEditCellValue({
            id,
            field,
            value: newValue,
        });
        console.log(isValid)

        if (isValid) {
            apiRef.current.stopCellEditMode({ id, field });
        }
    };

    return (
        <Autocomplete
            size='small'
            disablePortal
            options={validStates}
            sx={{
                width: 300,
                marginLeft: '10px'
            }}
            onChange={handleChange}
            value={value}
            renderInput={(params) =>
                <TextField {...params}
                    label="Status"
                    variant='standard'
                />
            }
        />
    );
}

const renderComboEditInputCell: GridColDef['renderCell'] = (params) => {
    return <ComboEditInputCell {...params} />;
};

interface TestKitsGridProps {
    testKits: any[],
    isFetching: boolean
}

const TestKitsGrid: React.FC<TestKitsGridProps> = ({ testKits, isFetching }) => {
    const [rows, setRows] = React.useState<any[]>(testKits);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    useEffect(() => {
        setRows(testKits)
    }, [testKits]);

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        setRows(rows.filter((row: any) => row.id !== id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow: any = rows.find((row: any) => row.id === id);
        if (editedRow!.isNew) {
            setRows(rows.filter((row: any) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row: any) => (row.ID === newRow.ID ? updatedRow : row)));
        return updatedRow;
    };

    const columns: GridColumns = [
        {
            field: 'ID',
            headerName: 'ID',
            type: 'string',
            width: 200,
        },
        {
            field: 'TestKitState',
            headerName: 'Status',
            type: 'string',
            minWidth: 200,
            flex: 1,
            editable: true,
            renderEditCell: renderComboEditInputCell,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: '100%',
            }}
        >
            <DataGrid
                loading={isFetching}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                componentsProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
                experimentalFeatures={{ newEditingApi: true }}
                getRowId={(row) => row.ID}
                sx={{
                    "& .MuiDataGrid-virtualScrollerRenderZone": {
                        "& .MuiDataGrid-row": {
                            "&:nth-child(2n)": {
                                backgroundColor: "rgba(235, 235, 235, .7)",
                                '&:hover, &.Mui-hovered': {
                                    backgroundColor: "rgba(235, 235, 235, 1)",
                                },
                            },
                            '&.Mui-selected': {
                                backgroundColor: mirSentinelTheme.palette.primary.light,
                                '&:hover, &.Mui-hovered': {
                                    backgroundColor: mirSentinelTheme.palette.primary.light,
                                }
                            }
                        },
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#cccccc",
                        borderTopLeftRadius: '1rem',
                        borderTopRightRadius: '1rem'
                    },
                    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                        borderRight: `1px solid #f0f0f0`,
                    },
                    '& .MuiDataGrid-iconSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bolder',
                        fontSize: 'larger',
                    },
                    borderRadius: '1rem'
                }}
            />
        </Box>
    );
};

export default TestKitsGrid;