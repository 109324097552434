import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';

import config from './config';
import Routes from './components/Routes';
import './App.css'
import MiRAppBarProvider from './mir-styles/src/providers/MirAppBarProvider';

const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
  const navigate = useNavigate()

  const restoreOriginalUri = useCallback(
    (_: any, originalUri = '/') => {
      const url = toRelativeUrl(originalUri, globalThis.location.origin)
      navigate(url, { replace: true })
    },
    // Allow "stale" navigate references as originalUri will be an absolute URL. 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    return () => {
      oktaAuth.options.restoreOriginalUri = undefined
    }
  }, [])

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div className="App">
        <MiRAppBarProvider>
          <Routes />
        </MiRAppBarProvider>
      </div>
    </Security>
  );
};

export default App;
