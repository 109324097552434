import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';

interface AddTestKitsModalProps {
  open: boolean,
  _handleClose: () => void,
  isDisabled?: boolean,
  children?: React.ReactNode,
  buttonProps?: ButtonProps,
  maxWidth?: boolean
}

const AddTestKitsModal: React.FC<AddTestKitsModalProps> = ({ open, _handleClose, isDisabled, children, buttonProps, maxWidth }) => {
  return (
    <Dialog 
    open={open}
    onClose={_handleClose}
    sx={{
      borderRadius: '2rem'
    }}
    >
      <DialogTitle>Add testkits</DialogTitle>
      <DialogContent>
        <DialogContentText>
          How many testkits should we add?
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Amount of testkits"
          fullWidth
          variant="standard"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={_handleClose}>Cancel</Button>
        <Button onClick={_handleClose} variant='contained'>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTestKitsModal;