import { Box, Grid, Paper } from "@mui/material";
import { useState } from "react";

import useCrud from "../../hooks/useCrud";
import TestKitsGrid from "./TestKitsGrid";
import MirButton from "../../mir-styles/src/components/MirButton/MirButton";
import AddTestKitsModal from "./AddTestKitsModal";
import "./TestKitsPage.css";

const TestKitsPage = () => {
  const [openAddTestkitsModal, setOpenAddTestkitsModal] =
    useState<boolean>(false);

  const {
    testKits,
    // currentTestKit,
    // editing,
    isFetching,
    // setEditing,
    // addTestKit,
    // bulkUploadTestKits,
    // deleteTestKit,
    // updateTestKit,
    // editRow,
    // exportTestKits
  } = useCrud();

  return (
    <>
      <AddTestKitsModal
        open={openAddTestkitsModal}
        _handleClose={() => setOpenAddTestkitsModal(false)}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 70px)",
          paddingTop: "30px",
          paddingBottom: "40px",
          paddingLeft: "50px",
          paddingRight: "50px",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            height: "100%",
            width: "70%",
            p: "40px 60px",
            display: "flex",
            flexDirection: "column",
            borderRadius: "2rem",
          }}
          elevation={6}
        >
          <Box
            sx={{
              display: "flex",
              minHeight: "45px",
              mb: "19px",
              alignItems: "center",
            }}
          >
            <Box sx={{ pl: "5px" }}>
              <h2>Testkits</h2>
            </Box>
            <Grid container justifyContent="flex-end">
              <Grid item m={1}>
                <MirButton onClick={() => setOpenAddTestkitsModal(true)} padding="14px 42px">
                  <h3 className="buttons__text">Add testkits</h3>
                </MirButton>
              </Grid>
              <Grid item m={1}>
                <MirButton padding="14px 42px">
                  <h3 className="buttons__text">Bulk upload</h3>
                </MirButton>
              </Grid>
              <Grid item m={1}>
                <MirButton  padding="14px 42px">
                  <h3 className="buttons__text">Export</h3>
                </MirButton>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <TestKitsGrid testKits={testKits} isFetching={isFetching} />
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default TestKitsPage;
